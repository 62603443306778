
import type { Database } from "$lib/types/supabase";
import { AbstractSupabaseApi } from "./AbstractSupabaseApi";
import { get } from "svelte/store";
import { page } from "$app/stores";

export type Profile = Database['public']['Tables']['profiles']['Insert'];
export type ProfileRow = Database['public']['Tables']['profiles'];

class ProfileApi extends AbstractSupabaseApi<ProfileRow> {
    override async create() {
        throw "You should not create a new profile here.";
    }

    async getUserByEmail(email: string): Promise<Profile> {
        const { error: createPostError, data } = await (get(page).data.supabase)
            .from(this.TABLE_NAME)
            .select("*")
            .eq("email", email)
            .limit(1)
            .single();
        if (createPostError) {
            throw createPostError;
        }

        return data;
    };

    async getById(id: string): Promise<Profile> {
        const { error: createPostError, data } = await (get(page).data.supabase)
            .from(this.TABLE_NAME)
            .select("*")
            .eq("id", id)
            .limit(1)
            .single();
        if (createPostError) {
            throw createPostError;
        }

        return data;
    }
}
export const profileApi = new ProfileApi('profiles');